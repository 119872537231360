<template>
  <!-- 菜单添加页面 -->
  <moe-page title="规格添加">
    <moe-form ref="specsForm" :model="specs" :rules="specsRules">
      <el-form-item label="规格名称" prop="name">
        <el-input v-model.trim="specs.name" placeholder="请输入规格名称" maxlength="50" clearable />
      </el-form-item>
      <el-form-item label="规格排序" prop="sort">
        <el-input :value="specs.sort" @input="(value) => specs.sort = value.replace(/^\.+|[^\d]/g,'')" placeholder="请输入规格排序，数值越大排序越靠前" maxlength="50" clearable />
      </el-form-item>
      <template slot="tool">
        <el-button type="primary" icon="el-icon-upload2" :loading="specsLoad" @click="specsInfoSubmit">
          {{ specsLoad ? '保存中' : '保存' }}
        </el-button>
      </template>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: 'SpecsAdd',
  data() {
    //菜单信息
    const specs = {
      name: '', //菜单
      sort: '', //排序
    };

    // 菜单数据校验
    const verify = this.$moe_verify.verifyForm;
    const specsRules = {
      name: verify.isEmpty('请输入规格名称'),
    };

    return {
      specs, //菜单信息
      specsRules, //菜单数据校验
      specsLoad: false, //提交状态
    };
  },
  methods: {
    /**
     * 提交账号信息
     **/
    specsInfoSubmit() {
      // 表单校验
      this.$refs['specsForm'].validate(() => {
        this.specsLoad = true;
        this.$moe_api.GOODS_API.specsAdd(this.specs)
          .then((data) => {
            if (data.code == 200) {
              this.$moe_msg.success('添加成功');
              this.$router.go(-1);
            } else {
              this.$moe_msg.error(data.message);
            }
          })
          .finally(() => {
            this.specsLoad = false;
          });
      });
    },
  },
};
</script>
